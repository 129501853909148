





































































































































































































































import Vue, { PropType } from "vue";
import { createNamespacedHelpers } from "vuex";

import locationStoreModule from "@/store/modules/location";
import { AdvancedPricing, Business, Role } from "@/types";
import { durations } from "@/util/constants";

const { mapActions: locationActions, mapGetters: locationGetters } =
  createNamespacedHelpers("NEARBY_LOCATIONS_SEARCH");

export default Vue.extend<any, any, any, any>({
  name: "EditAdvancedPricingOptionForm",
  props: {
    pricing: {
      type: Array as PropType<
        {
          durationInMinutes: number;
          price: number;
          name: string;
          staffPricing: {
            jobTitle: string;
            price: number;
            priceName: string;
            durationInMinutes: number;
          }[];
          locations: {
            price: number;
            name: string;
            zip: string;
            priceName: string;
            durationInMinutes: number;
          }[];
          deposit?: { amount: number; amountType: "percentage" | "amount" };
        }[]
      >,
      required: true,
    },
  },
  data: () => ({
    valid: false,
    locations: [] as { name: string; zip: string }[],
    durations,
    priceNameRules: [(v: string) => !!v || "Price Name field is required"],
    durationRules: [(v: string) => !!v || "Duration field is required"],
    priceRules: [(v: string) => !!v || "Price field is required"],
    depositAmount: "",
    depositPercentage: "",
    currency: "KES",
    depositType: "",
    search: "",
    pricingId: -1,
    requiresDeposit: false,
    advancedPricing: [] as AdvancedPricing[],
    selectedLocations: [] as string[],
    staff: [],
    deposit: {},
  }),
  computed: {
    ...locationGetters(["locationResult"]),
    role(): Role {
      return this.$store.getters.role;
    },
    prices(): { name: string; id: number }[] {
      return this.advancedPricing.map((item, i) => ({
        name: `Pricing Option ${i + 1}`,
        id: i,
      }));
    },
  },
  watch: {
    requiresDeposit: {
      handler: "depositChangeHandler",
      deep: true,
    },
    pricing() {
      // this.advancedPricing = { ...this.pricing };
    },
  },
  created() {
    this.advancedPricing = [...this.pricing];
    // this.fetchJobTitleList(
    //   `?businessId=${(this.role.business as Business)._id}`
    // ).then((page) => {
    //   if (page) {
    //     this.advancedPricing.map(
    //       (ap) =>
    //         (ap.staffPricing = page.docs.map((j: JobTitle) => ({
    //           jobTitle: j._id,
    //           durationInMinutes: 60,
    //           price: 0,
    //           priceName: j.name,
    //         })))
    //     );
    //   }
    // });
    // this.fetchLocationList(
    //   `?businessId=${(this.role.business as Business)._id}`
    // ).then((locationPage) => {
    //   if (locationPage) {
    //     this.locations = locationPage.docs; //.map((l: _Location) => l.name);
    //   }
    // });
  },
  methods: {
    ...locationActions(["fetchLocationList"]),
    selectLocation(locationIds: string[]) {
      const locations: {
        name: string;
        zip: string;
        price: number;
        priceName: string;
        durationInMinutes: number;
      }[] = [];
      locationIds.map((name) => {
        const location = this.locations.find((l) => l.name === name);
        locations.push({
          name: location?.name as string,
          zip: location?.zip as string,
          price: 0,
          priceName: "Offer",
          durationInMinutes: 60,
        });
      });
      this.search = "";
      this.advancedPricing[this.pricingId].locations = locations;
    },
    validateForm() {
      const valid = (
        this.$refs.pricingForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!valid) return;

      this.$emit("data-saved", this.advancedPricing);
    },
    searchLocation(q: string) {
      if (q) {
        const bid = (this.role.business as Business)._id;
        let params = `?businessId=${bid}&q=${q}`;
        this.fetchLocationList(params).then((locationPage) => {
          if (locationPage) {
            this.locations = locationPage.docs; //.map((l: _Location) => l.name);
          }
        });
      }
    },
    removeLocation(index: number) {
      // const loc = this.advancedPricing[this.pricingId].locations[index];
      // const indx = this.locations.findIndex((item) => item.name === loc.name);
      // this.advancedPricing[this.pricingId].locations.splice(index);
      // // this.locations.splice(indx);
      this.selectedLocations.splice(index);
    },
    setPricing(id: number) {
      this.pricingId = id;
      this.staff = this.pricing[id].staffPricing;
      this.selectedLocations = this.pricing[id].locations;
      this.requiresDeposit = !!this.pricing[id].deposit;
      this.deposit = this.advancedPricing[this.pricingId].deposit ?? {
        amount: 0,
        amountType: "amount",
      };
    },
    removeStaff(index: number) {
      this.staff.splice(index, 1);
    },
    depositChangeHandler() {
      if (!this.requiresDeposit)
        this.advancedPricing[this.pricingId].deposit = undefined;
      else {
        this.advancedPricing[this.pricingId].deposit = this.deposit;
      }
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("NEARBY_LOCATIONS_SEARCH")) {
      this.$store.registerModule(
        "NEARBY_LOCATIONS_SEARCH",
        locationStoreModule
      );
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("NEARBY_LOCATIONS_SEARCH");
  },
});
