

























































































































































































































































































































































































import Vue, { VueConstructor } from "vue";
import { createNamespacedHelpers } from "vuex";

import serviceStoreModule from "@/store/modules/service";
import serviceCategoryStoreModule from "@/store/modules/serviceCategory";
import nearbySearchStoreModule from "@/store/modules/location";
import jobTitleStoreModule from "@/store/modules/jobTitle";
import {
  Business,
  File as _File,
  Page,
  Role,
  Service,
  _Location,
} from "@/types";
import { durations } from "@/util/constants";
import UpdateAdvancedPricing from "@/components/service/UpdateAdvancedPricing.vue";

const API_URL = process.env.VUE_APP_API_URL;

type AdvancedPricing = {
  name: string;
  price: number;
  durationInMinutes: number;
  locations: any[];
  staff: any[];
};

const { mapActions: serviceActions } =
  createNamespacedHelpers("UPDATE_SERVICE_");
const {
  mapActions: serviceCategoryActions,
  mapGetters: serviceCategoryGetters,
} = createNamespacedHelpers("UPDATE_SERVICE_CATEGORY");

const { mapActions: locationActions, mapGetters: locationGetters } =
  createNamespacedHelpers("SERVICE_LOCATION_SEARCH");

const { mapGetters: jobTitleGetters, mapActions: jobTitleActions } =
  createNamespacedHelpers("JOB_TITLES");

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: {
        imageField0: HTMLInputElement;
        imageField1: HTMLInputElement;
        imageField2: HTMLInputElement;
        imageField3: HTMLInputElement;
      };
      locationPage: Page<_Location>;
    }
  >
).extend<any, any, any, any>({
  components: { UpdateAdvancedPricing },
  name: "UpdateServiceForm",
  props: {
    serviceId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    onlineBooking: true,
    virtualBooking: false,
    images: [] as _File[],
    name: "",
    categoryIds: [] as string[],
    availableFor: "",
    description: "",
    nameRules: [(v: string) => !!v || "Name field is required"],
    categoryRules: [(v: string) => !!v || "Category field is required"],
    maxSimultaneousClients: 0,
    maxSimultaneousClientsRules: [
      (v: string) => !!v || "Max Simultaneous Clients is required",
    ],
    selectedLocations: [] as _Location[],
    prices: [] as {
      name: string;
      location: string;
      price: number;
      durationInMinutes: number;
    }[],
    location: "",
    advancedPricing: [] as AdvancedPricing[],
    advancedPricingDialog: false,
    durations,
    durationRules: [(v: string) => !!v || "Duration field is required"],
    staffPricing: [] as {
      price: number;
      priceName: string;
      duration: number;
    }[],
    deposit: undefined as undefined | { amount: number; amountType: string },
    apiUrl: API_URL,
    newImages: [] as File[],
  }),
  watch: {
    role: "fetchData",
  },
  created() {
    this.fetchData();

    this.resetForm();
  },
  computed: {
    ...serviceCategoryGetters(["serviceCategoryPage"]),
    ...locationGetters(["locationPage"]),
    ...jobTitleGetters(["jobTitlePage"]),
    role(): Role {
      return this.$store.getters.role;
    },
    class_(): boolean {
      return this.$route.query.class === "true";
    },
    service(): Service {
      return this.$store.getters["UPDATE_SERVICE_/getService"](this.serviceId);
    },
    allLocations: {
      get() {
        return this.locationPage.docs.every(
          (item: _Location) =>
            !!this.selectedLocations.map((loc) => loc._id).includes(item._id)
        );
      },
      set(val) {
        if (val) {
          this.selectedLocations = this.locationPage.docs;
        } else this.selectedLocations = [];
      },
    },
  },
  methods: {
    ...serviceActions(["updateService", "uploadServiceImage", "fetchService"]),
    ...serviceCategoryActions(["fetchServiceCategoryList"]),
    ...locationActions(["fetchLocationList"]),
    ...jobTitleActions(["fetchJobTitleList"]),
    validateService() {
      const valid = (
        this.$refs.serviceForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!valid) return;

      const advancedPricing = this.advancedPricing;

      for (let i = 0; i < advancedPricing.length; i++) {
        const element = advancedPricing[i];

        advancedPricing[i].staffPricing = element.staffPricing.map((item) => ({
          ...item,
          ...{ jobTitle: item.jobTitle._id },
        }));

        advancedPricing[i].locations = advancedPricing[i].locations.map(
          (item) => ({
            name: item.name,
            price: item.price,
            priceName: item.priceName,
            durationInMinutes: item.durationInMinutes,
          })
        );

        advancedPricing[i] = {
          currency: advancedPricing[i].currency,
          name: advancedPricing[i].name,
          price: advancedPricing[i].price,
          locations: advancedPricing[i].locations,
          durationInMinutes: advancedPricing[i].durationInMinutes,
          deposit: advancedPricing[i].deposit,
          staffPricing: advancedPricing[i].staffPricing,
        };
      }

      this.updateService({
        id: this.serviceId,
        service: {
          name: this.name,
          categoryIds: this.categoryIds,
          // businessId: (this.role.business as Business)._id,
          // durationInMinutes: this.duration,
          interactionType: this.virtualBooking ? "virtual" : "in-person",
          // travelSurchargePerKilometer: 100,
          price: {
            // hourlyRate: 10,
          },
          description: this.description,
          // maxSimultaneousClients: this.maxSimultaneousClients,
          location: this.allLocations ? "All" : this.location,
          advancedPricing,
          images: this.images.map((i) => {
            delete i.id;
            return i;
          }),
          locations: this.selectedLocations.map((item) => item._id),
          deposit: this.service.deposit,
        },
      }).then((service) => {
        if (service) {
          if (this.newImages.length) {
            const formData = new FormData();
            this.newImages.map((i) => {
              if (i) formData.append("files", i);
            });
            this.uploadServiceImage({
              id: service._id,
              service: formData,
            }).then((s) => {
              if (s) this.$router.push("/services");
            });
          } else this.$router.push("/services");
        }
      });
    },
    selectLocation(l: _Location) {
      const indx = this.selectedLocations.findIndex(
        (item) => item._id === l._id
      );

      if (indx === -1) this.selectedLocations.push(l);
      else this.$delete(this.selectedLocations, indx);
    },
    upload(files: FileList, index: number) {
      this.$set(this.newImages, index, files[0]);
    },
    addPricingOption() {
      this.prices.push({
        price: 500,
        durationInMinutes: 30,
        name: "Offer",
        staffPricing: this.jobTitlePage.docs.map((item) => ({
          durationInMinutes: 30,
          jobTitle: item,
          price: 0,
          priceName: item.name,
        })),
        locations: this.locationPage.docs.map((item) => ({
          name: item.name,
          durationInMinutes: 30,
          price: 0,
          priceName: item.name,
        })),
      });
    },
    resetForm() {
      this.fetchService(`?serviceId=${this.serviceId}`).then((s) => {
        if (s) {
          // this.onlineBooking = true,
          this.virtualBooking = s.onlineBooking;
          this.images = [];
          this.name = this.service.name;
          this.categoryIds = this.service.categories.map((c) => c._id);
          this.availableFor = this.service.availableFor;
          this.description = this.service.description;
          this.maxSimultaneousClients = this.service.maxSimultaneousClients;
          this.prices = this.service.advancedPricing;
          this.advancedPricing = this.service.advancedPricing;
          this.location = this.service.location;
          this.deposit = this.service.deposit;
          this.availableFor = this.service.availableFor;
          this.images = this.service.images as _File[];
          this.newImages = [];
          this.selectedLocations = s.locations;
        }
      });
    },
    removeImage(index: number) {
      this.images.splice(index, 1);
    },
    getUrl(image: _File) {
      return image ? `url(${this.apiUrl}/v1/file/${image.filename})` : "";
    },
    fetchData() {
      if (!this.role) return;

      const bid = (this.role.business as Business)._id;
      let params = `?businessId=${bid}`;
      this.fetchServiceCategoryList(params);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.fetchLocationList(params + "&limit=1000");
      this.fetchJobTitleList(params + "&limit=1000");
    },
    setAdvancedPricing(pricing) {
      this.advancedPricing = pricing;
      this.advancedPricingDialog = false;
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("UPDATE_SERVICE_")) {
      this.$store.registerModule("UPDATE_SERVICE_", serviceStoreModule);
    }
    if (!this.$store.hasModule("UPDATE_SERVICE_CATEGORY")) {
      this.$store.registerModule(
        "UPDATE_SERVICE_CATEGORY",
        serviceCategoryStoreModule
      );
    }
    if (!this.$store.hasModule("SERVICE_LOCATION_SEARCH")) {
      this.$store.registerModule(
        "SERVICE_LOCATION_SEARCH",
        nearbySearchStoreModule
      );
    }
    if (!this.$store.hasModule("JOB_TITLES")) {
      this.$store.registerModule("JOB_TITLES", jobTitleStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("UPDATE_SERVICE_");
    this.$store.unregisterModule("UPDATE_SERVICE_CATEGORY");
    this.$store.unregisterModule("SERVICE_LOCATION_SEARCH");
    this.$store.unregisterModule("JOB_TITLES");
  },
});
